<template>
  <div class="px-2 mt-1">
    <!-- <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in tabs"
        :key="index"
        :to="{ name: item.route }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :id="'ab_' + index"
      >
        {{ item.title }}
      </b-nav-item>
    </b-nav> -->

    <router-view key="quality-main" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          route: "quality-clients-main",
          title: "Clients",
        },
      ],
    };
  },
};
</script>